import React from "react";
import './body.css';
import { Terminal } from "./components/Terminal";


export function Body() {

    const render = () => {
    
        return (
            <Terminal></Terminal>

        );
    }

    return (render());
}