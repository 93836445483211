import React from 'react';
import { Body } from "../body/Body.jsx";

const App = () => {
    
    return (
        <Body></Body>
    );
}

export default App;